import * as React from 'react'
import { Link, graphql } from 'gatsby'
import { PrismicLink, PrismicText } from '@prismicio/react'
import { StaticImage } from 'gatsby-plugin-image'
import { ProjectFiltersMenu } from './ProjectFiltersMenu'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { LanguageSwitcher } from './LanguageSwitcher'
import { Parallax } from 'react-scroll-parallax'
import { useState } from 'react'
import { LogosList } from './LogosList'

type TopMenuProps = {
  topMenu: any
  activeDocMeta: any
  activeProjectFilter: any
  setActiveProjectFilter: any
  categories: any
}

export const TopMenu: React.FC<TopMenuProps> = ({
  topMenu,
  activeDocMeta,
  activeProjectFilter,
  setActiveProjectFilter,
  categories,
}) => {
  const breakpoints = useBreakpoint()
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const openBurgerMenu = () => {
    !isMenuOpen ? setIsMenuOpen(true) : setIsMenuOpen(false)
  }

  const renderedMenuLinks = topMenu
    ? topMenu.menu_links.map((menuLink: any, index: any) => {
        return menuLink.link.url === '/' ? (
          <></>
        ) : (
          <>
            <div
              className="menuItem section"
              key={`menuItem-${menuLink.label}`}
            >
              <PrismicLink href={menuLink.link.url}>
                <p className="mainSectionTitle">
                  <PrismicText field={menuLink.label.richText} />
                </p>
              </PrismicLink>
            </div>
            •
          </>
        )
      })
    : null

  const renderMerchLink = () => {
    return (
      <div className="menuItem section" key={`menuItem-merch`}>
        <a href={'https://blacklezrecords.bigcartel.com/'} target="_blank">
          <p className="mainSectionTitle">Vinyls/T-Shirts</p>
        </a>
      </div>
    )
  }

  return (
    <header>
      <div className="leftside">
        <Link to="/">
          <div className={`headerLogo ${breakpoints.sm ? 'mobile' : ''}`}>
            <StaticImage
              src="../images/main_logo.png"
              alt="Bottleneck Jay"
              placeholder="none"
              className="logo"
            />
          </div>
        </Link>
      </div>
      <div className={`rightside ${breakpoints.sm ? 'mobile' : ''}`}>
        <div
          className={`menuWrapper ${breakpoints.sm ? 'mobile' : ''} ${
            isMenuOpen ? 'open' : 'close'
          }`}
        >
          {/* <ProjectFiltersMenu
                            activeProjectFilter={activeProjectFilter}
                            setActiveProjectFilter={setActiveProjectFilter}
                            categories={categories}
                        /> */}

          {breakpoints.sm ? (
            <>
              {renderedMenuLinks}
              {renderMerchLink()}
              <LogosList
                spotifyUrl={topMenu.top_menu_spotify_link.text}
                instagramUrl={topMenu.top_menu_instagram_link.text}
                facebookUrl={topMenu.top_menu_facebook_link.text}
                tiktokUrl={topMenu.top_menu_tiktok_link.text}
                appleUrl={topMenu.top_menu_apple_link.text}
                bandcampUrl={topMenu.top_menu_bandcamp_link.text}
                youtubeUrl={topMenu.top_menu_youtube_link.text}
              />
            </>
          ) : (
            <>
              <LogosList
                spotifyUrl={topMenu.top_menu_spotify_link.text}
                instagramUrl={topMenu.top_menu_instagram_link.text}
                facebookUrl={topMenu.top_menu_facebook_link.text}
                tiktokUrl={topMenu.top_menu_tiktok_link.text}
                appleUrl={topMenu.top_menu_apple_link.text}
                bandcampUrl={topMenu.top_menu_bandcamp_link.text}
                youtubeUrl={topMenu.top_menu_youtube_link.text}
              />
              {renderedMenuLinks}
              {renderMerchLink()}
            </>
          )}

          {/* <LanguageSwitcher activeDocMeta={activeDocMeta} /> */}
        </div>
        {breakpoints.sm && !isMenuOpen && (
          <div className="burgerMenu" onClick={openBurgerMenu}>
            <svg
              width="30px"
              height="30px"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 18L20 18"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M4 12L20 12"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
              />
              <path
                d="M4 6L20 6"
                stroke="#fff"
                stroke-width="2"
                stroke-linecap="round"
              />
            </svg>
          </div>
        )}
        {breakpoints.sm && isMenuOpen && (
          <div className="burgerMenu" onClick={openBurgerMenu}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              width="30px"
              height="30px"
            >
              <path
                fill="#fff"
                d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z"
              />
            </svg>
          </div>
        )}
      </div>
    </header>
  )
}

export const query = graphql`
  fragment TopMenuFragment on PrismicTopMenu {
    _previewable
    type
    lang
    data {
      top_menu_spotify_link {
        text
      }
      top_menu_instagram_link {
        text
      }
      top_menu_facebook_link {
        text
      }
      top_menu_tiktok_link {
        text
      }
      top_menu_apple_link {
        text
      }
      top_menu_bandcamp_link {
        text
      }
      top_menu_youtube_link {
        text
      }
      menu_links {
        label {
          richText
          html
          text
        }
        link {
          id
          url
        }
      }
    }
  }
`
