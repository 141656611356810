import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';
// import { Parallax } from 'react-scroll-parallax';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type ProjectCreditsListProps = {
    slice: any;
};

export const PageCreditsList: React.FC<ProjectCreditsListProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    return (
        <div className={`sliceProjectCreditsList container ${breakpoints.sm ? 'mobile' : ''}`}>
            {slice.primary.page_credits_section_title.text && (
                <p className="creditSectioTitle">{slice.primary.page_credits_section_title.text}</p>
            )}
            <div className="creditWrapper">
                <div className="creditItems">
                    {slice.items.map((item: any, index: number) => (
                        <div className="creditItem" key={'item_' + index}>
                            <div className="category">
                                <PrismicRichText field={item.page_credit_title.richText} />
                            </div>
                            <p className="separator">:</p>
                            <div className="names">
                                <PrismicRichText field={item.page_credit_desc.richText} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export const query = graphql`
    fragment PageDataPageCreditsList on PrismicPageDataBodyPageCreditsList {
        primary {
            page_credits_section_title {
                text
            }
        }
        items {
            page_credit_title {
                richText
            }
            page_credit_desc {
                richText
            }
        }
    }
`;
