import * as React from 'react'
import { graphql } from 'gatsby'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation } from 'swiper'
import { GatsbyImage } from 'gatsby-plugin-image'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type ImagesSlideShowProps = {
  slice: any
}

export const ImagesSlideShow: React.FC<ImagesSlideShowProps> = ({ slice }) => {
  const breakpoints = useBreakpoint()

  return (
    <div
      className={`sliceImagesSlideShow container ${
        breakpoints.sm ? 'mobile' : ''
      }`}
    >
      {slice.primary.slideshow_title.text && (
        <p className="sliceTitle">{slice.primary.slideshow_title.text}</p>
      )}
      <div className="slideShowWrapper">
        <Swiper
          slidesPerView={!breakpoints.sm ? 3 : 1}
          spaceBetween={10}
          slidesPerGroup={!breakpoints.sm ? 3 : 1}
          grabCursor={true}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
        >
          {slice.items.map((item: any, index: number) => (
            <SwiperSlide key={'item_' + index}>
              {/* <Parallax speed={!breakpoints.sm ? -9 * index : -3 * index}> */}
              <div className="item">
                <GatsbyImage
                  image={item.image.gatsbyImageData}
                  alt={item.image.alt ? item.image.alt : ''}
                />
              </div>
              {/* </Parallax> */}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ProjectDataImagesSlideshow on PrismicProjectDataBodyImagesSlideshow {
    primary {
      slideshow_title {
        text
      }
    }
    items {
      image {
        gatsbyImageData
        alt
        url
      }
    }
  }
  fragment PageDataImagesSlideshow on PrismicPageDataBodyImagesSlideshow {
    primary {
      slideshow_title {
        text
      }
    }
    items {
      image {
        gatsbyImageData
        alt
        url
      }
    }
  }
`
