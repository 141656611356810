import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type ImagesGridProps = {
  slice: any
}

export const ImagesGrid: React.FC<ImagesGridProps> = ({ slice }) => {
  const breakpoints = useBreakpoint()

  return (
    <div
      className={`sliceImagesGrid container ${breakpoints.sm ? 'mobile' : ''}`}
    >
      {slice.primary.images_grid_title.text && (
        <p className="sliceTitle">{slice.primary.images_grid_title.text}</p>
      )}
      <div className="gridWrapper">
        {slice.items.map((item: any, index: number) => (
          <div className="gridItem" key={'item_' + index}>
            <GatsbyImage
              image={item.image.gatsbyImageData}
              alt={item.image.alt ? item.image.alt : ''}
            />
            <div className="gridImageDesc">
              <PrismicRichText field={item.image_description.richText} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ProjectDataImagesGrid on PrismicProjectDataBodyImagesGrid {
    primary {
      images_grid_title {
        text
      }
    }
    items {
      image {
        gatsbyImageData
        alt
        url
      }
      image_description {
        richText
      }
    }
  }
  fragment PageDataImagesGrid on PrismicPageDataBodyImagesGrid {
    primary {
      images_grid_title {
        text
      }
    }
    items {
      image {
        gatsbyImageData
        alt
        url
      }
      image_description {
        richText
      }
    }
  }
`
