import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, PrismicText, SliceZone } from '@prismicio/react'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Layout } from '../components/Layout'
import { components } from '../slices'
import { ProjectHeaderFullScreen } from '../components/ProjectHeaderFullScreen'
import { Parallax } from 'react-scroll-parallax'
import { GatsbyImage } from 'gatsby-plugin-image'

type ProjectTemplateProps = {
  data?: any
}

const ProjectTemplate: React.FC<ProjectTemplateProps> = ({ data }) => {
  const breakpoints = useBreakpoint()
  if (!data) return null

  const projectContent = data.prismicProject
  const project = projectContent.data || {}

  const { lang, type, url } = projectContent
  const alternateLanguages = project.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  const topMenu = data.prismicTopMenu || {}
  const title = project.title.text || 'Untitled'

  const gameVitesseUrl = topMenu.data.menu_links.find(
    (menuItem) => menuItem.link.url == '/page/game-vitesse',
  )

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <div className="pageWrapper">
        <div className="fullScreen_content">
          <div className="fullScreen_img">
            <GatsbyImage
              image={project.project_background_image?.gatsbyImageData}
              alt={'Project background image'}
            />
          </div>

          {/* <Link to={project.project_external_link.text} target="_blank"> */}
          <div
            className={`projectFullScreenWrapper ${
              breakpoints.sm ? 'mobile' : ''
            }`}
          >
            <div className="header">
              {project.project_image_info?.gatsbyImageData && (
                <div className="projectFullScreenHeaderCopy">
                  <div className="imageContent">
                    {project.project_image_info && (
                      <div className="image">
                        <GatsbyImage
                          image={project.project_image_info?.gatsbyImageData}
                          alt={'Project info image image'}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              <div
                className={`projectFullScreenHeaderImg ${
                  !project.project_image_info?.gatsbyImageData ? 'centered' : ''
                }`}
              >
                {project.project_thumbnail && (
                  <div className="image">
                    <GatsbyImage
                      image={project.project_thumbnail?.gatsbyImageData}
                      alt={'Project thumbnail image'}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="projectDesc">
              <h2>{title}</h2>
              <a
                className="bandcampUrl"
                href={project.project_external_link_2.text}
                target="_blank"
              >
                <div className="logo">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title>Bandcamp</title>
                    <path
                      fill="#fff"
                      d="M0 18.75l7.437-13.5H24l-7.438 13.5H0z"
                    />
                  </svg>
                </div>
                <h5>
                  Bandcamp <span>Écouter/Acheter</span>
                </h5>
              </a>
              <a
                className="spotifyUrl"
                href={project.project_external_link.text}
                target="_blank"
              >
                <div className="logo">
                  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <title>Spotify</title>
                    <path
                      fill="#fff"
                      d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"
                    />
                  </svg>
                </div>
                <h5>Spotify</h5>
              </a>
              {project.project_description.richText.length > 0 && (
                <PrismicRichText field={project.project_description.richText} />
              )}
              {data.prismicProject.id ===
                'd0e28023-d122-5d00-ae4a-35874fd11d74' && (
                <Link to={gameVitesseUrl.link.url} target="_blank">
                  <h2>Jouer</h2>
                </Link>
              )}
            </div>
            <div className="mediasWrapper">
              <SliceZone slices={project.body} components={components} />
            </div>
          </div>
          {/* </Link> */}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ProjectQuery($id: String) {
    prismicProject(id: { eq: $id }) {
      _previewable
      id
      uid
      lang
      type
      url
      data {
        date
        title {
          text
        }
        project_subtitle {
          richText
        }
        project_thumbnail {
          gatsbyImageData
          alt
          copyright
        }
        project_image_info {
          gatsbyImageData
          alt
          copyright
        }
        project_background_image {
          gatsbyImageData
          alt
          copyright
        }
        project_external_link {
          text
        }
        project_external_link_2 {
          text
        }
        project_video_link {
          text
        }
        project_description {
          richText
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...ProjectDataBodyQuote
          ...ProjectDataBodyImageWithCaption
          ...ProjectDataBodyVideo
          ...ProjectDataBodyText
          ...ProjectDataCreditsList
          ...ProjectDataImagesGrid
          ...ProjectDataImagesSlideshow
        }
      }
    }
    prismicTopMenu {
      ...TopMenuFragment
    }
  }
`

export default withPrismicPreview(ProjectTemplate as any)
