import * as React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicRichText, SliceZone } from '@prismicio/react'

import { Layout } from '../components/Layout'
import { components } from '../slices'
import { Parallax } from 'react-scroll-parallax'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { Albums } from './albums'
import { GameVitesse } from './gameVitesse'

type PageTemplateProps = {
  data?: {
    prismicPage?: any
    prismicTopMenu?: any
    allPrismicProject?: any
  }
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data }) => {
  const breakpoints = useBreakpoint()
  if (!data) return null

  const pageContent = data.prismicPage
  const albums = data?.allPrismicProject || {}
  const page = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const title = page.page_title.text || 'Untitled'

  const pageGame = pageContent.id === '8617ee32-a03f-5183-bdde-86a1b6743a98'
  const pageAlbum = pageContent.id === '3d64d273-755a-516c-a4e9-d41e5101d6be'
  const pageMedias = pageContent.id === '2c581854-6589-554f-911b-f980e49c380e'
  const isSSR = typeof window === 'undefined'

  console.log('pageContent ', pageContent)

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <div
        className={`pageWrapper ${breakpoints.sm ? 'mobile' : ''} ${
          breakpoints.md ? 'medium' : ''
        }`}
      >
        <div className="fullScreen_content">
          <div className="fullScreen_img">
            <GatsbyImage
              image={page.page_images[0].page_image.gatsbyImageData}
              alt={'Page background image'}
            />
          </div>

          {pageGame && !isSSR && (
            <GameVitesse
              thumbnailsUrl={pageContent.data.page_images[1]}
              desc={pageContent.data.page_description.richText}
            />
          )}

          {pageAlbum && <Albums albums={albums} />}

          {!pageAlbum && !pageGame && (
            <div className="pageFullScreenHeader">
              <div className="wrapper">
                {page.page_content_blocs.map((bloc: any, index: number) => {
                  return (
                    <div className={`pageBloc`} key={'page_bloc_' + index}>
                      {bloc.content_bloc_title.text && (
                        <h6>{bloc.content_bloc_title.text}</h6>
                      )}
                      {bloc.content_bloc_desc.richText.length > 0 && (
                        <PrismicRichText
                          field={bloc.content_bloc_desc.richText}
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          )}
        </div>
        <div className={`pageMediasWrapper ${pageMedias && 'clips'} `}>
          <SliceZone slices={page.body} components={components} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query pageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      uid
      type
      id
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        page_description {
          richText
        }
        page_content_blocs {
          content_bloc_title {
            text
          }
          content_bloc_desc {
            richText
          }
        }
        page_images {
          page_image {
            gatsbyImageData
            alt
            copyright
          }
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...PageDataBodyFullWidthImage
          ...PageDataBodyTextInfo
          ...PageDataPageCreditsList
          ...PageDataBodyVideo
          ...PageDataImagesGrid
          ...PageDataImagesSlideshow
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    allPrismicProject(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        _previewable
        alternate_languages {
          uid
          type
          lang
        }
        lang
        id
        uid
        url
        tags
        data {
          title {
            text
          }
          project_subtitle {
            richText
          }
          date(formatString: "YYYY-MM-DD")
          project_thumbnail {
            gatsbyImageData
            alt
            copyright
          }
          project_categories {
            project_category {
              id
              uid
            }
          }
          body {
            ... on PrismicProjectDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(PageTemplate as any)
// export default PageTemplate;
