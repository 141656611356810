import * as React from 'react';
import { graphql } from 'gatsby';
import { PrismicRichText } from '@prismicio/react';

type TextInfoProps = {
    slice: any;
};

export const TextInfo: React.FC<TextInfoProps> = ({ slice }) => {
    return (
        <section className="text-info">
            {slice.primary.section_title.text && <p className="sliceTitle">{slice.primary.section_title.text}</p>}
            <div className="wrapperInfo">
                <div className="left-column">
                    <PrismicRichText field={slice.primary.left_column_text.richText} />
                </div>
                <div className="right-column">
                    <PrismicRichText field={slice.primary.right_column_text.richText} />
                </div>
            </div>
        </section>
    );
};

export const query = graphql`
    fragment PageDataBodyTextInfo on PrismicPageDataBodyTextInfo {
        primary {
            left_column_text {
                richText
            }
            right_column_text {
                richText
            }
            section_title {
                text
            }
        }
    }
    fragment HomepageDataBodyTextInfo on PrismicHomepageDataBodyTextInfo {
        primary {
            left_column_text {
                richText
            }
            right_column_text {
                richText
            }
            section_title {
                text
            }
        }
    }
`;
