import * as React from 'react';
import { graphql } from 'gatsby';
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

type QuoteProps = {
    slice: any;
};

export const Quote: React.FC<QuoteProps> = ({ slice }) => {
    const breakpoints = useBreakpoint();

    return (
        <div className={`post-quote container ${breakpoints.sm ? 'mobile' : ''}`}>
            <blockquote>{slice.primary.quote.text}</blockquote>
        </div>
    );
};

export const query = graphql`
    fragment ProjectDataBodyQuote on PrismicProjectDataBodyQuote {
        primary {
            quote {
                text
            }
        }
    }
`;
