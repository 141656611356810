import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import Unity, { UnityContent } from 'react-unity-webgl'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { PrismicRichText } from '@prismicio/react'

type GameVitesseProps = {
  thumbnailsUrl: any
  desc: any
}

export const GameVitesse: React.FC<GameVitesseProps> = ({
  thumbnailsUrl,
  desc,
}) => {
  let unitywrapper = new UnityContent(
    '../gameVitesse/Build/build-compress.json',
    '../gameVitesse/Build/UnityLoader.js',
  )

  const breakpoints = useBreakpoint()

  const [hideLoading, setHideLoading] = useState(false)
  setTimeout(() => {
    setHideLoading(true)
  }, 6000)

  return (
    <>
      <div className="wrapperGame">
        {(!hideLoading || breakpoints.sm) && (
          <div className="thumbnail">
            <>
              {breakpoints.sm && <h6>Only desktop</h6>}
              <GatsbyImage
                image={thumbnailsUrl.page_image.gatsbyImageData}
                alt={'thumbnailsUrl'}
              />
              <svg className="spinner" viewBox="0 0 50 50">
                <circle
                  className="path"
                  cx="25"
                  cy="25"
                  r="20"
                  fill="none"
                  stroke-width="5"
                ></circle>
              </svg>
            </>
          </div>
        )}
        <div className="mask">
          <Unity unityContent={unitywrapper} />;
        </div>
        {!breakpoints.sm && (
          <div className="wrapperDesc">
            <PrismicRichText field={desc} />
          </div>
        )}
      </div>
    </>
  )
}
