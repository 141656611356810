import * as React from 'react'
import { graphql } from 'gatsby'
import { PrismicRichText } from '@prismicio/react'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type TextProps = {
  slice: any
}

export const TextBloc: React.FC<TextProps> = ({ slice }) => {
  const breakpoints = useBreakpoint()

  return (
    <div className={`post-text container ${breakpoints.sm ? 'mobile' : ''}`}>
      <div>
        <PrismicRichText field={slice.primary.text.richText} />
      </div>
    </div>
  )
}

export const query = graphql`
  fragment ProjectDataBodyText on PrismicProjectDataBodyText {
    primary {
      text {
        richText
      }
    }
  }
`
