import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { PrismicLink, SliceZone } from '@prismicio/react'
import { Layout } from '../components/Layout'
import { components } from '../slices'
import { Projects } from '../components/Projects'
import { ProjectHeaderFullScreen } from '../components/ProjectHeaderFullScreen'
import { GatsbyImage } from 'gatsby-plugin-image'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { VideoReactPlayer } from './VideoReactPlayer'

type HomepageTemplateProps = {
  data?: {
    prismicHomepage?: any
    prismicTopMenu?: any
    allPrismicProject?: any
    allPrismicCategories?: any
  }
}

const HomepageTemplate: React.FC<HomepageTemplateProps> = ({ data }) => {
  const breakpoints = useBreakpoint()
  const [activeProjectFilter, setActiveProjectFilter] =
    useState('category_featured')
  const [activeCategoryName, setActiveCategoryName] = useState('')
  const [currentFilteredProjects, setCurrentFilteredProject] = useState([])

  const homepage = data?.prismicHomepage || {}
  const topMenu = data?.prismicTopMenu || {}
  const projects = data?.allPrismicProject || {}
  const categories = data?.allPrismicCategories || {}

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }

  //Create Projects List Array from Active Categories as Filter
  useEffect(() => {
    const featuredProjectsUidByActiveCategory: any = []
    const featuredProjectsListByActiveCategory: any = []

    //Get active categories object
    const activeCategory =
      activeProjectFilter &&
      categories.nodes.filter(
        (category: any) => category.uid === activeProjectFilter,
      )

    setActiveCategoryName(activeCategory[0].data.category_name.text)

    //Create the list of featured projects UID corresponding to the active category object
    activeCategory &&
      activeCategory[0].data.featured_projects.map((project: any) => {
        project !== undefined &&
          featuredProjectsUidByActiveCategory.push(project.featured_project.uid)
      })

    //Loop into all projects and sort all Featured one releated to the list above
    featuredProjectsUidByActiveCategory.length > 0 &&
      featuredProjectsUidByActiveCategory.map((projectUid: any) => {
        const project = projects.nodes.filter(
          (project: any) => project.uid === projectUid,
        )
        project !== undefined &&
          featuredProjectsListByActiveCategory.push(project[0])
      })

    //Loop in all projects to push all unfeatured project but tagged with active category
    projects.nodes.map((project: any) => {
      project.data.project_categories.map((category: any) => {
        if (category.project_category.uid === activeProjectFilter) {
          !featuredProjectsUidByActiveCategory.includes(project.uid) &&
            project !== undefined &&
            featuredProjectsListByActiveCategory.push(project)
        }
      })
    })

    setCurrentFilteredProject(featuredProjectsListByActiveCategory)
  }, [activeProjectFilter, categories.nodes, projects.nodes])

  // console.log('activeProjectFilter ', activeProjectFilter);
  // console.log('categories ', categories, activeCategoryName);
  // console.log('projects ', projects)

  ///FULLSCREENVIDEO
  const [videoHeight, setVideoHeight] = useState(0)

  const calculateHeight = () => {
    const height =
      window.outerWidth > 1024
        ? (window.innerWidth * 9) / 16
        : window.innerHeight
    return height
  }

  useEffect(() => {
    setVideoHeight(calculateHeight())
    function handleResize() {
      setVideoHeight(calculateHeight())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  })

  if (!data) return null

  return (
    <Layout
      topMenu={topMenu.data}
      activeProjectFilter={activeProjectFilter}
      setActiveProjectFilter={setActiveProjectFilter}
      categories={categories}
      activeDocMeta={activeDoc}
    >
      <div className="fullScreen_content fullScreen_content_homepage">
        {breakpoints.sm ? (
          <div className="homepage_mobile_backgroundImg">
            <GatsbyImage
              image={homepage.data.homepage_video_thumbnail?.gatsbyImageData}
              alt={'Homepage Bottleneck Jay mobile image'}
            />
          </div>
        ) : (
          <>
            <div
              className={`fullScreen_img hide ${
                breakpoints.sm ? 'mobile' : ''
              }`}
            >
              <GatsbyImage
                image={homepage.data.homepage_fullscreen_img?.gatsbyImageData}
                alt={'Homepage Bottleneck Jay image'}
              />
            </div>

            <div
              className="homepage_fullScreenVideo"
              style={{ height: `${videoHeight})px` }}
            >
              <div className="screen"></div>
              <div className="blackScreen"></div>
              <VideoReactPlayer
                videoUrl={homepage.data.homepage_video_url.text}
                hasControls={false}
                isLooping={true}
                isMuted={true}
                isAutoPlaying={true}
              />
            </div>
          </>
        )}

        <div className={`bottomWrapper ${breakpoints.sm ? 'mobile' : ''}`}>
          <a href={homepage.data.homepage_full_video_url.text} target="_blank">
            <div className="playBtn">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
                <polygon
                  className="play-btn__svg"
                  fill="#fff"
                  points="9.33 6.69 9.33 19.39 19.3 13.04 9.33 6.69"
                />
                <path
                  className="play-btn__svg"
                  fill="#fff"
                  d="M26,13A13,13,0,1,1,13,0,13,13,0,0,1,26,13ZM13,2.18A10.89,10.89,0,1,0,23.84,13.06,10.89,10.89,0,0,0,13,2.18Z"
                />
              </svg>
            </div>
          </a>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query homepageQuery($lang: String) {
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      alternate_languages {
        uid
        type
        lang
      }
      lang
      url
      type
      data {
        display_title {
          text
        }
        homepage_fullscreen_img {
          gatsbyImageData
          alt
          copyright
        }
        homepage_video_url {
          text
        }
        homepage_full_video_url {
          text
        }
        homepage_video_thumbnail {
          gatsbyImageData
          alt
          copyright
        }
        body {
          ... on PrismicSliceType {
            id
            slice_type
            slice_label
          }
          ...HomepageDataBodyFullWidthImage
          ...HomepageDataBodyTextInfo
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
    allPrismicProject(
      sort: { fields: data___date, order: DESC }
      filter: { lang: { eq: $lang } }
    ) {
      nodes {
        _previewable
        alternate_languages {
          uid
          type
          lang
        }
        lang
        id
        uid
        url
        tags
        data {
          title {
            text
          }
          project_subtitle {
            richText
          }
          date(formatString: "MMM D, YYYY")
          project_thumbnail {
            gatsbyImageData
            alt
            copyright
          }
          project_categories {
            project_category {
              id
              uid
            }
          }
          body {
            ... on PrismicProjectDataBodyText {
              id
              slice_label
              slice_type
              primary {
                text {
                  richText
                }
              }
            }
          }
        }
      }
    }
    allPrismicCategories(filter: { lang: { eq: $lang } }) {
      nodes {
        _previewable
        alternate_languages {
          uid
          type
          lang
        }
        lang
        id
        uid
        url
        tags
        data {
          category_name {
            text
          }
          featured_projects {
            featured_project {
              id
              uid
              url
            }
          }
        }
      }
    }
  }
`

export default withPrismicPreview(HomepageTemplate as any)
// export default HomepageTemplate;
