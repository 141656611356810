import React, { useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { GatsbyImage } from 'gatsby-plugin-image'

// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

// import required modules
import { EffectCoverflow, Pagination } from 'swiper/modules'
import { Link } from 'gatsby'

type albumsProps = {
  albums: any
}

export const Albums: React.FC<albumsProps> = ({ albums }) => {
  let filteredAlbums = albums.nodes.sort(
    (a, b) => new Date(...a.data.date) - new Date(...b.data.date),
  )
  const breakpoints = useBreakpoint()

  return (
    <div className="wrapperSlideshowAlbums">
      {breakpoints.sm ? (
        <>
          {filteredAlbums.map((item: any, index: number) => {
            const d = new Date(item.data.date)
            let year = d.getFullYear()
            return (
              <div className="wrapperListAlbum">
                <Link className="albumUrl" to={item.url}>
                  <div className="wrapperAlbum">
                    <GatsbyImage
                      image={item.data.project_thumbnail.gatsbyImageData}
                      alt={
                        item.data.project_thumbnail.alt
                          ? item.data.project_thumbnail.alt
                          : ''
                      }
                    />
                    <div className="albumInfo year">
                      <h4>{item.data.title.text}</h4>
                      <p>{`${year}`}</p>
                    </div>
                  </div>
                </Link>
              </div>
            )
          })}
        </>
      ) : (
        <Swiper
          effect={'coverflow'}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={1}
          navigation={breakpoints.sm ? false : true}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={true}
          modules={[EffectCoverflow, Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            640: {
              slidesPerView: 2,
            },
          }}
        >
          {filteredAlbums.map((item: any, index: number) => {
            const d = new Date(item.data.date)
            let year = d.getFullYear()

            return (
              <SwiperSlide key={'item_' + index}>
                <div className="item">
                  <Link className="albumUrl" to={item.url}>
                    <GatsbyImage
                      image={item.data.project_thumbnail.gatsbyImageData}
                      alt={
                        item.data.project_thumbnail.alt
                          ? item.data.project_thumbnail.alt
                          : ''
                      }
                    />
                  </Link>
                  <div className="albumInfo year">
                    <h4>{item.data.title.text}</h4>
                    <p>{`${year}`}</p>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </div>
  )
}
