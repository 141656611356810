import * as React from 'react'
import { graphql } from 'gatsby'
import { VideoPlayer } from '../components/VideoPlayer'
// @ts-ignore
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

type VideoPlayerSliceProps = {
  slice: any
}

export const VideoPlayerSlice: React.FC<VideoPlayerSliceProps> = ({
  slice,
}) => {
  const breakpoints = useBreakpoint()

  return (
    <div
      className={`wrapperVideoPlayerSlice container ${
        breakpoints.sm ? 'mobile' : ''
      }`}
    >
      <VideoPlayer
        videoUrl={slice.primary.video_url.text}
        isAutoplay={slice.primary.is_video_autoplay}
      />
    </div>
  )
}

export const query = graphql`
  fragment ProjectDataBodyVideo on PrismicProjectDataBodyVideo {
    primary {
      video_url {
        text
      }
    }
  }
  fragment PageDataBodyVideo on PrismicPageDataBodyVideo {
    primary {
      video_url {
        text
      }
    }
  }
`
