import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

type FullWidthImageProps = {
    slice: any;
};

export const FullWidthImage: React.FC<FullWidthImageProps> = ({ slice }) => {
    const isSSR = typeof window === 'undefined';
    return (
        <section className="full-width-image auto-grid">
            <div className="main-img">
                <GatsbyImage image={slice.primary.image?.gatsbyImageData} alt={slice.primary.image?.alt} />
            </div>
        </section>
    );
};

export const query = graphql`
    fragment PageDataBodyFullWidthImage on PrismicPageDataBodyFullWidthImage {
        primary {
            background_image_position
            image {
                gatsbyImageData
                alt
                copyright
            }
        }
    }
    fragment HomepageDataBodyFullWidthImage on PrismicHomepageDataBodyFullWidthImage {
        primary {
            background_image_position
            image {
                gatsbyImageData
                alt
                copyright
            }
        }
    }
`;
